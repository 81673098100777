<template>
  <vx-card>
    <!-- table -->
    <custom-vs-table
      ref="table"
      pagination
      stripe
      sst
      hoverFlat
      :data="lists.data"
      :max-items="lists.meta.pagination.per_page"
      :total="lists.meta.pagination.total"
      @change-page="handleChangePage"
    >

      <template slot="thead">
        <vs-th class="thead">No</vs-th>
        <vs-th class="thead" sort-key="name">Name</vs-th>
        <vs-th class="thead">Phone</vs-th>
        <vs-th class="thead">Email</vs-th>
<!--        <vs-th class="thead">Message</vs-th>-->
<!--        <vs-th class="thead">Remark</vs-th>-->
        <vs-th class="thead">Status</vs-th>
        <vs-th class="thead">Date</vs-th>
        <vs-th class="thead">Action</vs-th>
      </template>

      <template>
        <vs-tr v-for="(contactUs, index) in lists.data" :key="index">
          <vs-td>
            {{ rowNo(index) }}
          </vs-td>
          <vs-td>
            {{ contactUs.firstname }} {{contactUs.lastname}}
          </vs-td>
          <vs-td>
            {{ contactUs.phone_number }}
          </vs-td>
          <vs-td>
            {{ contactUs.email }}
          </vs-td>
<!--          <vs-td style="white-space: pre-wrap;">-->
<!--            {{ contactUs.message }}-->
<!--          </vs-td>-->
<!--          <vs-td style="white-space: pre-wrap;">-->
<!--            {{ contactUs.remark }}-->
<!--          </vs-td>-->
          <vs-td>
            <cell-renderer-status :status="contactUs.status" />
          </vs-td>
          <vs-td>
            <span>
              {{ $helpers.dateFormat(contactUs.created_at) }}
            </span>
          </vs-td>
          <vs-td>
            <vs-button
              class="float-left"
              color="primary"
              type="border"
              icon-pack="feather"
              icon="icon-edit-2"
              :to="{
                name: 'contact-us.edit',
                params: {
                  id: contactUs.id,
                },
              }"
            />
            <vs-button
              class="float-left"
              color="danger"
              type="filled"
              icon-pack="feather"
              icon="icon-trash-2"
              @click.prevent="remove(contactUs.id)"
            />
          </vs-td>
        </vs-tr>
      </template>
    </custom-vs-table>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { default as listMixin } from '@/mixins/listMixin'
import CustomVsTable from '@/components/table/CustomVsTable'
import CellRendererStatus from '@/components/table/cell-renderer/ContactUsStatus'

export default {
  mixins: [listMixin],
  components: {
    CustomVsTable,
    CellRendererStatus,
  },
  computed: {
    ...mapGetters('data', ['contactUs']),
    ...mapGetters('contactUs', ['lists', 'info']),
  },
  methods: {
    ...mapActions('contactUs', ['fetch', 'find', 'delete', 'reset']),

    async _fetch () {
      this.locked = true

      await this.fetch({ ...this.lodash.get(this, 'params', {}) })

      this.locked = false
    },
  },

  async mounted () {
    this._fetch()
  },
}
</script>
